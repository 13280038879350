import { Component, OnInit, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { DecimalPipe, NgIf } from '@angular/common';
import { AccountsService } from '../../service/accounts.service';
import { ResourceInterface, ResourcesDataInterface } from '../../model/resource.model';
import { CookiesStorageService, cookiesKey } from '../../service/cookies-storage.service';
import RouterConfig from '../../../configs/router.config';
import { RouterService } from '../../service/router.service';

@Component({
  selector: 'app-resource-banner',
  standalone: true,
  imports: [MatButton, TranslateModule, MatIcon, NgIf, DecimalPipe],
  templateUrl: './resource-banner.component.html',
  styleUrl: './resource-banner.component.scss',
})
export class ResourceBannerComponent implements OnInit {
  private routerService = inject(RouterService);
  private accountsService = inject(AccountsService);
  private cookiesStorageService = inject(CookiesStorageService);

  bannerVisible = false;
  exceedingResources: string[] = [];
  private threshold = 0.8;
  private isBannerClosedByUser = false;

  ngOnInit(): void {
    this.isBannerClosedByUser = this.cookiesStorageService.getCookie(cookiesKey.resourceBanner) === 'true';
    if (!this.isBannerClosedByUser) {
      this.getResourcesData();
    }
  }

  private getResourcesData(): void {
    this.accountsService.getResources().subscribe((response: ResourcesDataInterface) => {
      this.exceedingResources = this.checkResourceLimits(response);
      this.bannerVisible = !this.isBannerClosedByUser && this.exceedingResources.length > 0;
    });
  }

  private isLimitCloseToExhaustion(resource: ResourceInterface): boolean {
    if (resource.limit === null || resource.used === null) return false;
    return resource.limit > 0 && resource.used / resource.limit >= this.threshold;
  }

  private checkResourceLimits(resources: ResourcesDataInterface): string[] {
    if (!resources) {
      return [];
    }

    return Object.entries(resources)
      .filter(([, value]) => this.isLimitCloseToExhaustion(value))
      .map(([key]) => key);
  }

  closeBanner(): void {
    this.bannerVisible = false;
    this.cookiesStorageService.setCookie(cookiesKey.resourceBanner, 1);
  }

  navigateToPlan(): void {
    this.routerService.navigate(RouterConfig.paymentsPlan);
  }
}
